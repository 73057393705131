import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Collapse, TextField } from "@mui/material";
import styled from "@emotion/styled";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { PbFontBlack } from "../../../app/colors";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ListTitle = styled.div((props: { isMobile: number }) => ({...{
  fontSize: props.isMobile === 1 ? "3.2cqi" : "1.2cqi",
  color: PbFontBlack,
  textAlign: 'left',
  marginBottom: "10px",
  fontWeight: 500,
} as React.CSSProperties}));

const TransferItemButton = styled(Button)((props: { isMobile: number }) => ({
  margin: props.isMobile === 1 ? "0.6cqi 0" : "0.2cqi 0",
  width: props.isMobile === 1 ? "8cqi" : "4cqi",
  minWidth: props.isMobile === 1 ? "12cqi" : "4cqi",
  height: props.isMobile === 1 ? "7cqi" : "2cqi",
  fontSize: props.isMobile === 1 ? "4cqi" : "1cqi",
  padding: props.isMobile === 1 ? "1cqi" : "0.5cqi",
}));

const SearchTextFiled = styled(TextField)((props: { isMobile: number }) => ({
  "& input": {
    fontSize: props.isMobile === 1 ? "3.4cqi" : "0.9cqi",
    padding: props.isMobile === 1 ? "1cqi" : "0.5cqi",
  },
  "& fieldset": {
    height: "100%",
    "& legend": {
      display: "none",
    },
  },
}));

const ItemsCard = styled(Card)({});

const ItemsCardHeader = styled(CardHeader)((props: { isMobile: number }) => ({
  padding:
    props.isMobile === 1
      ? "0.9cqi 0.6cqi 0.9cqi 3cqi"
      : "0.3cqi 0.2cqi 0.3cqi 1cqi",
  "& div": {
    "& span": {
      fontSize: props.isMobile === 1 ? "3.4cqi" : "0.9cqi",
    },
  },
}));

const ItemCheckbox = styled(Checkbox)((props: { isMobile: number }) => ({
  height: props.isMobile === 1 ? "5cqi" : "1.5cqi",
  minWidth: props.isMobile === 1 ? "8cqi" : "4cqi",
  "& svg": {
    width: props.isMobile === 1 ? "5cqi" : "1.5cqi",
    height: props.isMobile === 1 ? "5cqi" : "1.5cqi",
  },
}));

const ItemsList = styled(List)((props: { isMobile: number }) => ({
  width: "100%",
  height: "18cqi",
  bgcolor: "background.paper",
  overflow: "auto",
}));

const ItemsListItem = styled(ListItem)((props: { isMobile: number }) => ({
  padding:
    props.isMobile === 1
      ? "0.9cqi 0.6cqi 0.9cqi 3cqi"
      : "0.3cqi 0.2cqi 0.3cqi 1cqi",
}));

const ItemsInnerListItem = styled(ListItem)((props: { isMobile: number }) => ({
  padding:
    props.isMobile === 1
      ? "0.9cqi 0.6cqi 0.9cqi 6cqi"
      : "0.3cqi 0.2cqi 0.3cqi 2cqi",
}));

const ItemsListItemIcon = styled(ListItemIcon)({});

const ItemsListItemText = styled(ListItemText)(
  (props: { isMobile: number }) => ({
    "& span": {
      fontSize:  props.isMobile === 1 ? "3.4cqi" : "0.8cqi",
    },
  })
);

const ExpandMoreIcon = styled(ExpandMore)({});

const ExpandLessIcon = styled(ExpandLess)({});

const ChevronRightButton = styled(ChevronRightIcon)((props: { isMobile: number }) => ({
 width: props.isMobile === 1 ? "5cqi" : "1.2cqi",
 height: props.isMobile === 1 ? "5cqi" : "1.2cqi",
}))

const ChevronLeftButton = styled(ChevronLeftIcon)((props: { isMobile: number }) => ({
  width: props.isMobile === 1 ? "5cqi" : "1.2cqi",
 height: props.isMobile === 1 ? "5cqi" : "1.2cqi",
}))

interface CustomTransferListProps<T> {
  initialItems: T[];
  initialSelectedItems: T[];
  itemKey: (item: T) => string;
  itemLabel: (item: T) => string;
  onSearch: (items: T[], searchValue: string) => T[];
  onSelected: (selectedItems: T[]) => void;
  getGroups: (
    items: T[],
    filter: string
  ) => {
    title: string;
    items: T[];
  }[];
  leftTitle: string;
  rightTitle: string;
  rightSearchPlaceHolder: string;
  leftSearchPlaceHolder: string;
}

export default function CustomTransferList<T>(
  props: CustomTransferListProps<T>
) {
  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  function not(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a: T[], b: T[]) {
    return [...a, ...not(b, a)];
  }

  const [checked, setChecked] = React.useState<T[]>([]);
  const [left, setLeft] = React.useState<T[]>(props.initialItems);
  const [right, setRight] = React.useState<T[]>(props.initialSelectedItems);

  const [collapsedGroupsLeft, setCollapsedGroupsLeft] = React.useState<
    string[]
  >([]);
  const [collapsedGroupsRight, setCollapsedGroupsRight] = React.useState<
    string[]
  >([]);

  const [searchValueLeft, setsearchValueLeft] = React.useState<string>("");
  const [searchValueRight, setsearchValueRight] = React.useState<string>("");

  const filteredLeft = React.useMemo(() => {
    const result = props.onSearch(left, searchValueLeft);
    return result;
  }, [left, props, searchValueLeft]);

  const filteredRight = React.useMemo(() => {
    const result = props.onSearch(right, searchValueRight);
    return result;
  }, [props, right, searchValueRight]);

  const leftChecked = intersection(intersection(checked, left), filteredLeft);
  const rightChecked = intersection(
    intersection(checked, right),
    filteredRight
  );

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: T[]) => intersection(checked, items).length;

  const handleGroupChecked = (items: T[], groupChecked: boolean) => {
    if (groupChecked) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleToggleGroup = (
    items: T[],
    group: string,
    side: "left" | "right"
  ) => {
    if (side === "left") {
      const isChecked = isGroupChecked(items);
      if (isChecked) {
        handleGroupChecked(items, true);
      } else {
        handleGroupChecked(items, false);
      }
    } else {
      const isChecked = isGroupChecked(items);
      if (isChecked) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    }
  };

  const handleToggleAll = (items: T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    props.onSelected(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.onSelected(not(right, rightChecked));
  };

  const isGroupChecked = (items: T[]) => {
    const allItemsChecked =
      intersection(checked, items).length === items.length;
    return allItemsChecked;
  };

  const isCollapsed = (group: string, side: "left" | "right") => {
    const collapsed =
      side === "left" ? collapsedGroupsLeft : collapsedGroupsRight;
    return collapsed.indexOf(group) !== -1;
  };

  const collapseGroup = (group: string, side: "left" | "right") => {
    if (side === "left") {
      setCollapsedGroupsLeft([...collapsedGroupsLeft, group]);
    } else {
      setCollapsedGroupsRight([...collapsedGroupsRight, group]);
    }
  };

  const expandGroup = (group: string, side: "left" | "right") => {
    if (side === "left") {
      const index = collapsedGroupsLeft.indexOf(group);
      if (index !== -1) {
        const newCollapsedGroups = [...collapsedGroupsLeft];
        newCollapsedGroups.splice(index, 1);
        setCollapsedGroupsLeft(newCollapsedGroups);
      }
    } else {
      const index = collapsedGroupsRight.indexOf(group);
      if (index !== -1) {
        const newCollapsedGroups = [...collapsedGroupsRight];
        newCollapsedGroups.splice(index, 1);
        setCollapsedGroupsRight(newCollapsedGroups);
      }
    }
  };

  const customList = (
    title: React.ReactNode,
    items: T[],
    onSearchChange: (value: string) => void,
    side: "left" | "right"
  ) => (
    <Column>
      <ListTitle isMobile={isMobile ? 1 : 0}>{title}</ListTitle>
      <SearchTextFiled
        isMobile={isMobile ? 1 : 0}
        placeholder={
          side === "left"
            ? props.leftSearchPlaceHolder
            : props.rightSearchPlaceHolder
        }
        size="small"
        onChange={(e) => {
          if (e.target.value.length < 100) {
            onSearchChange(e.target.value);
          }
        }}
      />
      <ItemsCard>
        <ItemsCardHeader
          isMobile={isMobile ? 1 : 0}
          avatar={
            <ItemCheckbox
              isMobile={isMobile ? 1 : 0}
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <ItemsList isMobile={isMobile ? 1 : 0} dense role="list">
          {props
            .getGroups(
              items,
              side === "left" ? searchValueLeft : searchValueRight
            )
            .map((group) => {
              return (
                <>
                  <ItemsListItem
                    isMobile={isMobile ? 1 : 0}
                    key={"key-" + group}
                    role="listitem"
                  >
                    <ItemsListItemIcon>
                      <ItemCheckbox
                        isMobile={isMobile ? 1 : 0}
                        checked={
                          numberOfChecked(group.items) === group.items.length
                        }
                        indeterminate={
                          numberOfChecked(group.items) !== group.items.length &&
                          numberOfChecked(group.items) !== 0
                        }
                        tabIndex={-1}
                        disableRipple
                        onClick={() => {
                          handleToggleGroup(group.items, group.title, side);
                        }}
                      />
                    </ItemsListItemIcon>
                    <ItemsListItemText
                      isMobile={isMobile ? 1 : 0}
                      id={"label-" + group}
                      primary={
                        <span style={{ fontWeight: 700 }}>{group.title}</span>
                      }
                    />
                    {isCollapsed(group.title, side) ? (
                      <span
                        onClick={() => {
                          expandGroup(group.title, side);
                        }}
                      >
                        <ExpandMore />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          collapseGroup(group.title, side);
                        }}
                      >
                        <ExpandLess />
                      </span>
                    )}
                  </ItemsListItem>
                  <Collapse in={!isCollapsed(group.title, side)} unmountOnExit>
                    {group.items.map((value) => {
                      const labelId = `transfer-list-all-item-${value}-label`;
                      return (
                        <ItemsInnerListItem
                          isMobile={isMobile ? 1 : 0}
                          key={props.itemKey(value)}
                          role="listitem"
                          onClick={handleToggle(value)}
                        >
                          <ItemsListItemIcon>
                            <ItemCheckbox
                              isMobile={isMobile ? 1 : 0}
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </ItemsListItemIcon>
                          <ItemsListItemText
                            isMobile={isMobile ? 1 : 0}
                            id={labelId}
                            primary={props.itemLabel(value)}
                          />
                        </ItemsInnerListItem>
                      );
                    })}
                  </Collapse>
                </>
              );
            })}
        </ItemsList>
      </ItemsCard>
    </Column>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
        {customList(
          props.leftTitle,
          filteredLeft,
          (value: string) => {
            setsearchValueLeft(value);
          },
          "left"
        )}
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
        <Grid container direction="column" alignItems="center">
          <TransferItemButton
            isMobile={isMobile ? 1 : 0}
            variant="outlined"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ChevronRightButton isMobile={isMobile ? 1 : 0}/>
          </TransferItemButton>
          <TransferItemButton
            isMobile={isMobile ? 1 : 0}
            variant="outlined"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ChevronLeftButton isMobile={isMobile ? 1 : 0}/>
          </TransferItemButton>
        </Grid>
      </Grid>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
        {customList(
          props.rightTitle,
          filteredRight,
          (value: string) => {
            setsearchValueRight(value);
          },
          "right"
        )}
      </Grid>
    </Grid>
  );
}
